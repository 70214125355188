var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center my-4"},[_c('h2',[_vm._v(_vm._s(_vm.content))]),_c('div',{attrs:{"id":"parent"}},[_c('div',{staticClass:"child"},[_c('l-map',{staticClass:"l-map",attrs:{"zoom":_vm.zoom,"center":_vm.center,"options":{attributionControl: false}}},[_c('l-tile-layer',{attrs:{"url":_vm.url,"attribution":_vm.attribution}}),_c('l-control-attribution',{attrs:{"position":"topright","prefix":"A custom prefix"}}),_c('l-marker',{attrs:{"lat-lng":_vm.markerLatLng}},[_c('l-popup',[_vm._v(_vm._s(_vm.content))])],1)],1),_vm._m(0)],1),_c('div',{staticClass:"child"},[_c('div',{staticClass:"mb-4"},[_vm._m(1),_c('p',{staticClass:"h4"},[_vm._v("- "+_vm._s(_vm.description)+" ")])]),_c('div',{staticClass:"mb-4"},[_vm._m(2),_c('p',{staticClass:"h4"},[_vm._v("- "+_vm._s(_vm.region))])]),_c('div',{staticClass:"mb-4"},[_vm._m(3),_c('p',{staticClass:"h4"},[_vm._v("- "+_vm._s(_vm.operator))])]),_c('div',{staticClass:"mb-4"},[_vm._m(4),_c('p',{staticClass:"h4"},[_vm._v("- "+_vm._s(_vm.launch_pads))])]),_c('div',{staticClass:"mb-4"},[_vm._m(5),_c('p',{staticClass:"h4"},[_vm._v("- "+_vm._s(_vm.first_launch))])])])]),_c('hr',{staticClass:"my-4"})])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text-right"},[_c('a',{attrs:{"target":"_blank","href":"https://leafletjs.com/","rel":"noopener"}},[_vm._v("Leaflet.js")]),_vm._v(" | © "),_c('a',{attrs:{"target":"_blank","href":"http://osm.org/copyright","rel":"noopener"}},[_vm._v("OpenStreetMap")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"h5 text-uppercase text-secondary"},[_c('strong',[_vm._v("Description:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"h5 text-uppercase text-secondary"},[_c('strong',[_vm._v("Location:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"h5 text-uppercase text-secondary"},[_c('strong',[_vm._v("Primary Operator:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"h5 text-uppercase text-secondary"},[_c('strong',[_vm._v("Launch Pads:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"h5 text-uppercase text-secondary"},[_c('strong',[_vm._v("First Launch:")])])
}]

export { render, staticRenderFns }